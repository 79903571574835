import React, { useEffect, useState } from 'react';
import { ADMIN_PET_DATA_CHANGE_FLOW } from '../../../constants/AdminFlows';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/inputs/select/CustomSelect';
import { isMobile, isMobileOnly } from 'react-device-detect';
import breedListDogs from '../../../assets/dog_breeds.json';
import breedListCats from '../../../assets/cat_breeds.json';
import Datepicker from '../../../components/common/components/Datepicker';
import { Request } from '../../../config/requests';
import moment from 'moment';
import Loading from '../../../components/common/Loading';
import { showToast } from '../../../config/helpers';

const AdminPetDataChange = ({ handleClose }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [policyName, setPolicyName] = useState('');
  const [policyId, setPolicyId] = useState('');
  const [initialPetType, setInitialPetType] = useState('');
  const [initialChip, setInitialChip] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [chipError, setChipError] = useState(false);
  const [initialChipError, setInitialChipError] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isPolicyFetching, setIsPolicyFetching] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [initPetData, setInitPetData] = useState({});
  const [petData, setPetData] = useState({});
  // console.log('petData', petData)
  // console.log('initPetData', initPetData)

  // Calculate date limits
  const today = new Date();

  // 8 weeks ago
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 8 * 7); // 8 weeks = 8 * 7 days

  // 9 years ago
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() - 9); // Go back 9 years

  const isValidNumber = (str) => {
    return /^[0-9]{15}$/.test(str);
  };

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setPetData((prevData) => ({ ...prevData, petChip: value }))
        setChipError(false);
      } else {
        setPetData((prevData) => ({ ...prevData, petChip: value }))
        setChipError(true);
      }
    }
  };

  const handleInitialChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setInitialChip(value);
        setInitialChipError(false);
      } else {
        setInitialChip(value);
        setInitialChipError(true);
      }
    }
  };

  async function getPolicyByChip(chipNumber) {
    setIsPolicyFetching(true);
    try {
      setSelectedItem(null);
      const res = await Request({
        method: 'get',
        route: `api/policy/chipnumber/${chipNumber}`,
      });
      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }
      const data = await res.json();
      setPolicyId(data.policyId);
      setInitialPetType(data.petType);
      setPolicyName(data.petName);

      const initData = {
        petName: data?.petName || '',
        petBreedType: data?.breedType || '',
        petBreed: data?.breed || '',
        petFirstBreed: data?.firstBreed || '',
        petSecondBreed: data?.secondBreed || '',
        petBD: moment(data?.dateOfBirth, "YYYY-MM-DD").toDate() || null,
        petChip: data?.chipNumber || '',
        petGender: data?.petGender || '',
        dogHeight: data?.sizeOfPet || '',
        catHabitat: data?.catType || '',
      };
      setInitPetData({ ...initData });
      setPetData({ ...initData });

      let breedList = data.petType === 'Dog' ? breedListDogs : breedListCats;

      if (data?.breed) {
        const foundBreed = breedList.find((i) => i.value_name === data?.breed);
        setInitPetData((prevData) => ({ ...prevData, petBreed: { label: foundBreed?.value_name, value: foundBreed?.value } }))
        setPetData((prevData) => ({ ...prevData, petBreed: { label: foundBreed?.value_name, value: foundBreed?.value } }))
      }
      if (data?.firstBreed) {
        const firstFoundBreed = breedList.find((i) => i.value_name === data?.firstBreed);
        setInitPetData((prevData) => ({ ...prevData, petFirstBreed: { label: firstFoundBreed?.value_name, value: firstFoundBreed?.value } }))
        setPetData((prevData) => ({ ...prevData, petFirstBreed: { label: firstFoundBreed?.value_name, value: firstFoundBreed?.value } }))
      }
      if (data?.secondBreed) {
        const secondFoundBreed = breedList.find((i) => i.value_name === data?.secondBreed);
        setInitPetData((prevData) => ({ ...prevData, petSecondBreed: { label: secondFoundBreed?.value_name, value: secondFoundBreed?.value } }))
        setPetData((prevData) => ({ ...prevData, petSecondBreed: { label: secondFoundBreed?.value_name, value: secondFoundBreed?.value } }))
      }

    } catch (e) {
      console.error(e);
      showToast(t('somethingWentWrong'), { error: true });
    } finally {
      setIsPolicyFetching(false);
    }
  }

  async function handleSubmit() {
    setSubmitting(true);
    const fd = new FormData();
    fd.append('petType', initialPetType);
    fd.append('policyId', policyId);

    fd.append('breedType', petData.petBreedType !== initPetData.petBreedType ? petData.petBreedType : '');
    fd.append('breed', (
      petData.petBreed !== null &&
      petData.petBreed?.label !== initPetData.petBreed?.label &&
      petData.petBreed.label !== undefined)
      ? petData.petBreed.label
      : '');
    fd.append('hybridBreed1', (
      petData.petFirstBreed !== null &&
      petData.petFirstBreed?.label !== initPetData.petFirstBreed?.label &&
      petData.petFirstBreed.label !== undefined)
      ? petData.petFirstBreed.label
      : '');
    fd.append('hybridBreed2', (
      petData.petSecondBreed !== null &&
      petData.petSecondBreed?.label !== initPetData.petSecondBreed?.label &&
      petData.petSecondBreed.label !== undefined)
      ? petData.petSecondBreed.label
      : '');
    fd.append('nameOfPet', petData.petName !== initPetData.petName ? petData.petName : '');
    fd.append('dateOfBirth', petData.petBD !== initPetData.petBD ? moment(petData.petBD).format('DD.MM.YYYY') : '');
    fd.append('chipNumber', petData.petChip !== initPetData.petChip ? petData.petChip : '');
    fd.append('petGender', petData.petGender !== initPetData.petGender ? petData.petGender : '');
    fd.append('sizeOfPet', petData.dogHeight !== initPetData.dogHeight ? petData.dogHeight : '');
    fd.append('catType', petData.catHabitat !== initPetData.catHabitat ? petData.catHabitat : '');

    try {
      const res = await Request({
        method: 'put',
        route: `api/pet/${policyId}`,
        formData: fd,
      });
      if (!res.ok) {
        throw new Error('handleSubmit failed')
      }
      setSubmitting(false);
      setIsCompleted(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setSelectedItem(null);
      showToast(t('somethingWentWrong'), { error: true });
    }
  }

  const handlePetType = (e) => {
    setPetData((prevData) => ({
      ...prevData,
      petType: e.target.value,
      petBreed: '',
      petFirstBreed: '',
      petSecondBreed: '',
    }));
  };

  const isDisabled = (el) => {
    switch (el.disablingValue) {
      case 'petName':
        return !petData.petName;
      case 'petChip':
        return !(petData.petChip && !chipError);
      case 'petBD':
        return !moment(petData.petBD).isValid();
      case 'petBreed':
        if (petData.petBreedType === 'Pure') {
          return !petData.petBreed;
        } else if (petData.petBreedType === 'Hybrid') {
          return !(petData.petFirstBreed && petData.petSecondBreed);
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  useEffect(() => {
    let options = [];
    let breedList = initialPetType == 'Dog' ? breedListDogs : breedListCats;

    breedList.forEach((el) => {
      options.push({ label: el.value_name, value: el.value });
    });
    if (petData.petBreedType === 'Pure') options.shift();

    if (petData.petFirstBreed) {
      options = options.filter((breed) => breed.value !== petData.petFirstBreed.value);
    }
    if (petData.petSecondBreed) {
      options = options.filter((breed) => breed.value !== petData.petSecondBreed.value);
    }
    setBreedOptions(options);
  }, [initialPetType, petData.petBreedType, petData.petFirstBreed, petData.petSecondBreed]);

  useEffect(() => {
    setPetData({ ...initPetData })
  }, [selectedItem])

  if (isCompleted) {
    return (
      <>
        <div
          className="modal_container__body__text"
          style={{
            maxWidth: '500px',
            margin: '40px 0',
            textAlign: 'start',
          }}
        >
          {t('dataChangedSuccess')}
        </div>

        <div className="modal_container__body__row-modal">
          <div
            className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
            onClick={handleClose}
          >
            OK
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="wrapper w-100 d-flex gap-4 flex-column h-auto">
      {ADMIN_PET_DATA_CHANGE_FLOW.map((section, index) => {
        return (
          <div key={`section-${index}`} className="section-container w-100">
            {Object.entries(section.components).map(
              ([key, step], stepIndex) => {
                // Check if step is an object with a type property
                if (typeof step === 'object' && step.type) {
                  if (
                    (step.type === 'text_input' && !step.dependency) ||
                    (step.type === 'text_input' &&
                      step.dependency == selectedItem?.value)
                  ) {
                    return (
                      <div
                        key={`text-step-${stepIndex}`}
                        className="modal_container__body__inputs align-items-start"
                      >
                        <label htmlFor={step.id}>{t(step.text)}</label>
                        <input
                          id={step.id}
                          className="modal_container__body__input w-100"
                          placeholder={t(step.placeholder)}
                          required={step.required || false}
                          disabled={step.disabled || false}
                          style={{ width: '100%' }}
                          value={
                            section.formType != 'search'
                              ? ''
                              : step.id == 'confirmPet'
                                ? policyName
                                : initialChip
                          }
                          onChange={(e) => handleInitialChip(e)}
                        />
                        {step.id === 'Chipnummer' &&
                          initialChipError && (
                            <div className="modal_container__body__error FastShow">
                              {t('chipWrong')}
                            </div>
                          )}
                      </div>
                    );
                  }

                  if (step.type === 'button') {
                    {
                      return isPolicyFetching ? (
                        <Loading noText fast width={'100px'} scroll={false} />
                      ) : (
                        <div
                          key={`button-step-${stepIndex}`}
                          className="modal_container__body__inputs align-items-start"
                        >
                          <button
                            id={step.id}
                            disabled={initialChipError || initialChip.length === 0}
                            className={`button_red_small__admin ${step.btnType || ''
                              }`}
                            onClick={() => {
                              step.btnType === 'submit'
                                ? getPolicyByChip(initialChip)
                                : undefined;
                            }}
                          >
                            {t(step.text)}
                          </button>
                        </div>
                      );
                    }
                  }

                  if (step.type == 'select') {
                    const options = step.options.map((el) => ({
                      value: el.value,
                      label: t(el.label),
                    }));
                    return (
                      <>
                        <div
                          key={`button-step-${stepIndex}`}
                          className="modal_container__body__inputs align-items-start"
                        >
                          <label htmlFor={step.id}>{t(step.text)}</label>
                          <CustomSelect
                            variant="modal-edit-data"
                            options={options}
                            value={selectedItem}
                            formatCreateLabel={(i) => i}
                            placeholder={t('pleaseSelectHere')}
                            onChange={(e) => setSelectedItem(e)}
                            isClearable={true}
                            fullWidth
                            disabled={!policyName}
                          />
                        </div>
                      </>
                    );
                  }

                  // if (
                  //   (step.type == 'date' && !step.dependency) ||
                  //   (step.dependency && step.dependency == selectedItem?.value)
                  // ) {
                  //   return (
                  //     <>
                  //       <div
                  //         key={`button-step-${stepIndex}`}
                  //         className="modal_container__body__inputs align-items-start w-100 "
                  //       >
                  //         <label htmlFor={step.id}>{t(step.text)}</label>
                  //         <div className="d-flex gap-3 w-100">
                  //           <CustomSelect
                  //             variant="modal-edit-data"
                  //             options={generateYears()}
                  //             formatCreateLabel={(i) => i}
                  //             placeholder={t('YY')}
                  //             value={years}
                  //             onChange={(e) => setYears(e)}
                  //             isClearable={true}
                  //             toTranslate={false}
                  //           />
                  //           <CustomSelect
                  //             variant="modal-edit-data"
                  //             options={generateMonths()}
                  //             formatCreateLabel={(i) => i}
                  //             placeholder={t('MM')}
                  //             value={months}
                  //             onChange={(e) => setMonths(e)}
                  //             isClearable={true}
                  //             toTranslate={false}
                  //           />
                  //           <CustomSelect
                  //             variant="modal-edit-data"
                  //             options={generateDays()}
                  //             formatCreateLabel={(i) => i}
                  //             placeholder={t('DD')}
                  //             value={days}
                  //             onChange={(e) => setDays(e)}
                  //             isClearable={true}
                  //             toTranslate={false}
                  //           />
                  //         </div>
                  //       </div>
                  //     </>
                  //   );
                  // }
                }

                return null; // Ignore non-object steps or steps without a type
              }
            )}

            {section.externalProcesses &&
              selectedItem &&
              Object.values(
                Object.values(section.externalProcesses).find(
                  (el) => el.title == selectedItem?.value
                ).steps
              ).map((el, index) => {
                if (index == activeStep) {
                  return (
                    <div key={el.id}>
                      <div
                        className="modal_container__body__text"
                        style={{
                          maxWidth: 'unset',
                          justifyContent: 'flex-start',
                          display: 'flex',
                        }}
                      >
                        <span>{t(el.text)}</span>
                      </div>

                      {/* {el.type == 'text' && (
                        <>
                          <div
                            className="modal_container__body__inputs"
                            style={{
                              maxWidth: 'unset',
                              justifyContent: 'flex-start',
                              display: 'flex',
                            }}
                          >
                            <input
                              className="modal_container__body__input"
                              placeholder={t(el.placeholder)}
                              style={{ width: '100%' }}
                              value={
                                el.id == 'name'
                                  ? petData.petName
                                  : el.id == 'chipNumber'
                                    ? petData.petChip
                                    : ''
                              }
                              onChange={(e) => {
                                el.id == 'name' && setPetData((prevData) => ({ ...prevData, petName: e.target.value }));
                                el.id == 'chipNumber' && handleChip(e);
                              }}
                            />
                            {chipError && (
                              <div className="modal_container__body__error FastShow">
                                {t('chipWrong')}
                              </div>
                            )}
                          </div>
                        </>
                      )} */}

                      {el.type == 'text_input' && (
                        <>
                          <div className="modal_container__body__inputs">
                            <input
                              className="modal_container__body__input"
                              placeholder={t(el.placeholder)}
                              value={
                                el.id == 'name'
                                  ? petData.petName
                                  : el.id == 'chipNumber'
                                    ? petData.petChip
                                    : ''
                              }
                              onChange={(e) => {
                                el.id == 'name' && setPetData((prevData) => ({ ...prevData, petName: e.target.value }));
                                el.id == 'chipNumber' && handleChip(e);
                              }}
                              style={{ width: '100%' }}
                            />
                            {chipError && (
                              <div className="modal_container__body__error FastShow">
                                {t('chipWrong')}
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      {el.type == 'radio-options' && (
                        <div
                          className="modal_container__body__text"
                          style={{ maxWidth: '540px' }}
                        >
                          <div className="modal_container__body__row-modal">
                            {el.options.map((option, optionKey) => {
                              return (
                                <div
                                  key={optionKey}
                                  className={`button_red_small__admin ${isMobile ? 'wide' : ''
                                    } ${el.id == 'height'
                                      ? petData.dogHeight === option.value
                                        ? 'button_red_small_active'
                                        : ''
                                      : ''
                                    } ${el.id == 'gender'
                                      ? petData.petGender === option.value
                                        ? 'button_red_small_active'
                                        : ''
                                      : ''
                                    } ${el.id == 'habitat'
                                      ? petData.catHabitat === option.value
                                        ? 'button_red_small_active'
                                        : ''
                                      : ''
                                    }
                                    `}
                                  onClick={() => {
                                    el.id == 'height' &&
                                      setPetData((prevData) => ({ ...prevData, dogHeight: option.value }));
                                    el.id == 'gender' &&
                                      setPetData((prevData) => ({ ...prevData, petGender: option.value }));
                                    el.id == 'habitat' &&
                                      setPetData((prevData) => ({ ...prevData, catHabitat: option.value }));
                                  }}
                                >
                                  {t(option.label)}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {/* {el.type == 'radio' && (
                        <>
                          <div className="modal_container__body__inputs">
                            <div className="modal_container__body__radio">
                              <label
                                style={{
                                  display: 'block',
                                  marginBottom: '8px',
                                }}
                              >
                                <input
                                  type="radio"
                                  value="Pure"
                                  checked={petBreedType === 'Pure'}
                                  onChange={(e) => {
                                    handleResetBreedValues();
                                    setPetBreedType(e.target.value);
                                  }}
                                  style={{ marginRight: '8px' }}
                                />
                                {t('pureBreed')}
                              </label>
                            </div>
                            <div className="modal_container__body__radio">
                              <label
                                style={{
                                  display: 'block',
                                  marginBottom: '8px',
                                }}
                              >
                                <input
                                  type="radio"
                                  value="Hybrid"
                                  checked={petBreedType === 'Hybrid'}
                                  onChange={(e) => {
                                    handleResetBreedValues();
                                    setPetBreedType(e.target.value);
                                  }}
                                  style={{ marginRight: '8px' }}
                                />
                                {t('hybridBeed')}
                              </label>
                            </div>
                          </div>
                        </>
                      )} */}

                      {el.type == 'date' && (
                        <>
                          <div className="modal_container__body__dropdowns justify-content-start">
                            <Datepicker
                              date={petData.petBD}
                              fullSize={true}
                              setDate={(newValue) => setPetData((prevData) => ({ ...prevData, petBD: newValue }))}
                              disabled={false}
                              type={'reset-dp-modal'}
                              minDate={maxDate}
                              maxDate={minDate}
                            />
                          </div>
                        </>
                      )}

                      {el.type == 'multiSteps' && (
                        <>
                          {Object.values(el.steps)
                            .filter((el) => el.isAdmin != false)
                            .map((innerStep, innerIndex) => {
                              return (
                                <div key={innerIndex}>
                                  {t(innerStep.title)}

                                  {innerStep.type == 'radio' && (
                                    <div className="modal_container__body__inputs">
                                      {innerStep.options.map(
                                        (option, optionIndex) => (
                                          <div
                                            key={optionIndex}
                                            className="modal_container__body__radio"
                                            style={{ width: '100%', maxWidth: '540px' }}
                                          >
                                            <label
                                              style={{
                                                display: 'flex',
                                                marginBottom: '8px',
                                              }}
                                              className={(innerStep.id == 'breedType' && petData.petBreedType === option.value || innerStep.id === 'petType' && petData.petType === option.value) && "checked"}
                                            >
                                              <input
                                                type="radio"
                                                value={option.value}
                                                checked={
                                                  innerStep.id == 'breedType'
                                                    ? petData.petBreedType === option.value
                                                    : innerStep.id === 'petType' && petData.petType === option.value
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    innerStep.id == 'breedType'
                                                  ) {
                                                    // handleResetBreedValues();
                                                    setPetData((prevData) => ({ ...prevData, petBreedType: e.target.value }));
                                                  } else if (
                                                    innerStep.id == 'petType'
                                                  ) {
                                                    handlePetType(e);
                                                  }
                                                }}
                                                style={{ marginRight: '8px' }}
                                              />
                                              {t(option.label)}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}

                                  {innerStep.type == 'breeds-select' &&
                                    petData.petBreedType == 'Pure' &&
                                    innerStep.dependency == petData.petBreedType && (
                                      <div className="modal_container__body__inputs">
                                        {
                                          <>
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={breedOptions}
                                              formatCreateLabel={(i) => i}
                                              placeholder={t(
                                                'pleaseSelectHere'
                                              )}
                                              value={petData.petBreed}
                                              onChange={(e) => setPetData((prevData) => ({ ...prevData, petBreed: e }))}
                                              isClearable={true}
                                              toTranslate={false}
                                              fullWidth
                                            />
                                          </>
                                        }
                                      </div>
                                    )}

                                  {innerStep.type == 'breeds-select' &&
                                    petData.petBreedType == 'Hybrid' &&
                                    innerStep.dependency == petData.petBreedType && (
                                      <div className="modal_container__body__inputs">
                                        {
                                          <>
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={breedOptions}
                                              formatCreateLabel={(i) => i}
                                              placeholder={t(
                                                'pleaseSelectHere'
                                              )}
                                              value={petData.petFirstBreed}
                                              onChange={(e) => setPetData((prevData) => ({ ...prevData, petFirstBreed: e }))}
                                              isClearable={true}
                                              toTranslate={false}
                                              fullWidth
                                            />
                                            <CustomSelect
                                              variant="modal-edit-data"
                                              options={breedOptions}
                                              formatCreateLabel={(i) => i}
                                              placeholder={t(
                                                'pleaseSelectHere'
                                              )}
                                              value={petData.petSecondBreed}
                                              onChange={(e) => setPetData((prevData) => ({ ...prevData, petSecondBreed: e }))}
                                              isClearable={true}
                                              toTranslate={false}
                                              fullWidth
                                            />
                                          </>
                                        }
                                      </div>
                                    )}

                                  {innerStep.type == 'radio-options' &&
                                    (petData.petBreedType == 'Unknown' ||
                                      (petData.petBreedType == 'Hybrid' && (petData.petFirstBreed?.value == 'DOG_UNKNOWN' || petData.petSecondBreed?.value == 'DOG_UNKNOWN'))) &&
                                    initialPetType == 'Dog' && (
                                      <div className="modal_container__body__inputs">
                                        <div className="modal_container__body__row-modal">
                                          <div
                                            className={`button_red_small__admin ${isMobile ? 'wide' : ''
                                              } ${petData.dogHeight === '0-44cm'
                                                ? 'button_red_small_active'
                                                : ''
                                              }`}
                                            style={{ minWidth: '200px' }}
                                            onClick={() => setPetData((prevData) => ({ ...prevData, dogHeight: '0-44cm' }))}
                                          >
                                            0 - 44 cm
                                          </div>
                                          <div
                                            className={`button_red_small__admin ${isMobile ? 'wide' : ''
                                              } ${petData.dogHeight === '> 44cm'
                                                ? 'button_red_small_active'
                                                : ''
                                              }`}
                                            style={{ minWidth: '200px' }}
                                            onClick={() => setPetData((prevData) => ({ ...prevData, dogHeight: '> 44cm' }))}
                                          >
                                            &gt; 44 cm
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </>
                      )}

                      <div className='align-items-start'>
                        {submitting ? (
                          <Loading noText fast width={'150px'} scroll={false} />
                        ) : (
                          <button
                            className={`button_red_small__admin`}
                            style={{ marginBottom: '10px' }}
                            disabled={isDisabled(el) || submitting || false}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            {t('Bestätigen')}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        );
      })}
    </div>
  );
};

export default AdminPetDataChange;
