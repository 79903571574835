// fetchInterceptor.js
import { jwtDecode } from 'jwt-decode';

const checkTokenExpiry = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};
const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

const originalFetch = window.fetch;

const fetchInterceptor = async (...args) => {
  try {
    const [resource, config] = args;
    if (config.headers.Authorization) {
        let token= config.headers.Authorization.substring(7)
        const isExpired = checkTokenExpiry(token);
      if (isExpired) {
        logout();
        return;
      }
    }

    const response = await originalFetch(resource, config);

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        logout();
      } else {
        throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
      }
    }

    return response;
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
};

export default fetchInterceptor;
