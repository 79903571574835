import moment from 'moment';
import { Request } from '../../config/requests';
import i18n from 'i18next';

export async function requestPrice(policyId, setPriceList, pendingPrice, t) {
  pendingPrice(true);

  return Request({
    method: 'get',
    route: `api/v2/all_coverage_info?policyId=${policyId}`,
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }
      return res.json();
    })
    .then((json) => {
      setPriceList(json.data);
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      pendingPrice(false);
    });
}

export async function sendUserRequest(
  message,
  selectedFiles,
  selectedPetInfo,
  setIsSubmitting,
) {
  setIsSubmitting(true);
  const fd = new FormData();
  selectedFiles.forEach((i) => fd.append('files', i));
  fd.append('reportType', 'Change contract request');
  fd.append('customerEmail', selectedPetInfo?.quote?.customer?.email);
  fd.append('customerName',selectedPetInfo?.quote?.customer?.firstName+' '+selectedPetInfo?.quote?.customer?.name)
  fd.append('policyNo', selectedPetInfo?.policy?.policyNo);
  fd.append('policyId', selectedPetInfo?.quote?.policy?.id);
  fd.append('name', selectedPetInfo?.data?.nameOfPet);
  fd.append('tariff', selectedPetInfo?.products?.petInsurance?.data?.selectedPackage);
  fd.append('message', message);

  return Request({
    method: 'post',
    route: 'api/users/change_data_request',
    formData: fd,
  })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
}

export async function sendTariffRequest(
  selectedFiles,
  selectedPetInfo,
  setIsSubmitting,
  formData,
) {
  setIsSubmitting(true);
  const fd = new FormData();
  selectedFiles.forEach((i) => fd.append('files', i));
  fd.append('reportType', 'Change tariff request');
  fd.append('customerEmail', selectedPetInfo?.quote?.customer?.email);
  fd.append('policyNo', selectedPetInfo?.policy?.policyNo);
  fd.append('policyId', selectedPetInfo?.quote?.policy?.id);
  fd.append('name', selectedPetInfo?.data?.nameOfPet);
  fd.append('tariff', selectedPetInfo?.products?.petInsurance?.data?.selectedPackage);
  fd.append('coverage', selectedPetInfo?.products?.petInsurance?.data?.selectedCoverage);
  fd.append('newTariff', formData.tariff);
  fd.append('customerName',selectedPetInfo?.quote?.customer?.firstName+' '+selectedPetInfo?.quote?.customer?.name)
  fd.append('newCoverage', formData.deductible);
  let healthStatusOptions= {
    'no_illness':i18n.t('option_noClaimsENG'),
    'no_illness_6month' : i18n.t('option_noClaimsSixMonthsENG') ,
    'had_illness' :i18n.t('option_wasClaimedENG') ,
  };
  fd.append('healthStatus', healthStatusOptions[formData.health_status]);
  fd.append('mtaDate', moment(formData.date).format('DD.MM.YYYY'));

  return Request({
    method: 'post',
    route: 'api/users/change_data_request',
    formData: fd,
  })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
}

export async function submitUpdates(
  policyID,
  tariff,
  coverage,
  mta_date,
  setIsSubmitting,
) {
  setIsSubmitting(true);

  return Request({
    method: 'post',
    route: `api/policy/change_contract`,
    values: {
      policyId: policyID,
      newTariff: tariff,
      newCoverage: coverage,
      mtaDate: mta_date,
    },
  })
    .catch((error) => {
      console.error(error);
      throw new Error(error);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
}
