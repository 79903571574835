import * as React from 'react';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { formatAnyDate, showToast } from '../../../../config/helpers';
import ModalWrapper from '../../../../components/modalWrapper';
import CatDog from '../../../../assets/Insurances/cat_dog.svg';
import Exclamation from '../../../../assets/iconsSmall/exclamation_mark_dark.svg';
import Attach from '../../../../assets/iconsSmall/paper_clip.svg';
import Remove from '../../../../assets/iconsSmall/cross_black.svg';
import Upload from '../../../../assets/iconsSmall/upload_icon.svg';
import { Request } from '../../../../config/requests';
import { Markup } from 'interweave';
import CustomSelect from '../../../../components/inputs/select/CustomSelect';
import Loading from '../../../../components/common/Loading';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function ModalCancelContract({
  openModalCancelContract,
  setOpenModalCancelContract,
}) {
  const router = useNavigate();
  const { t } = useTranslation();
  const pets = useSelector((s) => s.flow.pets);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [selectedPetInfo, setSelectedPetInfo] = useState(null);
  const [dialog, setDialog] = useState('main');
  const [selectedType, setSelectedType] = useState(null);
  const [circumstances, setCircumstances] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkingRevoke, setCheckingRevoke] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [canRevoke, setCanRevoke] = useState(false);
  
  const cancelTypeMap = {
    'Ordinary cancelation': 'ordinary_cancelation',
    'Revocation': 'revocation',
  }

  const circumstancesList = [
    { label: t('Animal given away'), value: 'Animal given away' },
    { label: t('Animal sold'), value: 'Animal sold' },
    {
      label: t('Animal handed over to animal welfare'),
      value: 'Animal handed over to animal welfare',
    },
    { label: t('Animal deceased'), value: 'Animal deceased' },
    { label: t('Policyholder deceased'), value: 'Policyholder deceased' },
    { label: t('Moving abroad'), value: 'Moving abroad' },
    { label: t('Miscellaneous'), value: 'Miscellaneous' },
  ];

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function handleClose() {
    setSelectedPetId(null);
    setSelectedPetInfo(null);
    setSelectedType(null);
    setCircumstances(null);
    setSelectedFiles([]);
    setDialog('main');
    setOpenModalCancelContract(false);
  }

  function selectPet(pet) {
    setSelectedPetId(pet?.policy.id);
    setSelectedPetInfo(pet);
    setDialog('cancelationType');
    checkRevoke(pet?.policy?.policyNo);
  }

  async function checkRevoke(policyNo) {
    setCheckingRevoke(true);
    return Request({
      method: 'get',
      route: `api/v2/can_revoke?policyNo=${policyNo}`,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        setCanRevoke(json?.data);
      })
      .catch((err) => {
        console.error(err);
        showToast(t('somethingWentWrong'), { error: true });
      })
      .finally(() => {
        setCheckingRevoke(false);
      });
  }

  async function handleSubmit() {
    setSubmitting(true);
    setDialog('emailSent');
    const fd = new FormData();
    selectedFiles.forEach((i) => fd.append('files', i));
    fd.append('reportType', 'Cancelation');
    fd.append('customerEmail', selectedPetInfo?.quote?.customer?.email);
    fd.append('customerName',selectedPetInfo?.quote?.customer?.firstName+' '+selectedPetInfo?.quote?.customer?.name)
    fd.append('policyNo', selectedPetInfo?.policy?.policyNo);
    fd.append('policyId', selectedPetInfo?.quote?.policy?.id);
    fd.append('name', selectedPetInfo?.data?.nameOfPet);
    fd.append(
      'tariff',
      selectedPetInfo?.products?.petInsurance?.data?.selectedPackage
    );
    fd.append('selectedType', selectedType);
    fd.append('reason', circumstances?.value);

    return Request({
      method: 'post',
      route: 'api/users/change_data_request',
      formData: fd,
    })
      .then((res) => {
        if (!res.ok)
          throw new Error(`Request failed with status ${res.status}`);
      })
      .catch(() => {
        showToast(t('somethingWentWrong'), { error: true });
        router('/');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  async function cancelContract() {
    let terminationDate;
    if(selectedPetInfo?.policy?.customerType==="Opt-OUT"&& selectedType=="revocation"){
terminationDate=moment().add(90,'days').format('DD.MM.YYYY')
    }else if( selectedType=="revocation"){
      terminationDate=moment().add(45,'days').format('DD.MM.YYYY');
    }else{
      terminationDate=moment().format('DD.MM.YYYY');
    }
    setSubmitting(true);
    setDialog('emailSent');
    Request({
      method: 'post',
      route: 'api/policy/cancel_contract',
      values: {
        origin: 'CUSTOMER',
        type: cancelTypeMap[selectedType],
        policyId: selectedPetId,
        cancelDate:
          selectedType === 'Ordinary cancelation'
            ? moment(selectedPetInfo?.policy?.dateExpiry).format('DD.MM.YYYY')
            : terminationDate,
        customerType:selectedPetInfo?.policy?.customerType
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
      })
      .catch((e) => {
        showToast(t('somethingWentWrong'), { error: true });
        router('/');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const modalTitle = (selectedType) => {
    switch (selectedType) {
      default:
        return ['cancelContract', 'kündigen'];
      case 'Revocation':
        if (dialog === 'typeSelected' || dialog === 'emailSent') {
          return ['Revocation', 'widerrufen'];
        }
        return ['cancelContract', 'kündigen'];
    }
  }

  const renderPets = () => {
    const rows = [];
    const numPets = pets.length;

    for (let i = 0; i < numPets; i += 3) {
      const rowPets = pets.slice(i, i + 3);
      const row = (
        <div key={`row-${i}`} className="pet_container__row-modal">
          {rowPets.map((pet, ind) => (
            <div
              key={ind}
              className={`pet_container__item ${pet.policy.id !== selectedPetId ? 'not-selected' : ''}`}
              onMouseOver={() => setSelectedPetId(pet?.policy.id)}
              onClick={() => selectPet(pet)}
            >
              <div className='pet_container__wrapper-modal small'>
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div
                className="pet_container__name"
                style={{ maxWidth: isMobileOnly ? '78px' : '' }}
              >
                {pet?.attributes?.nameOfPet}
                {pets.filter(
                  (el) => el.attributes.nameOfPet === pet.attributes.nameOfPet
                ).length > 1 && <div>{pet?.policy.policyNo}</div>}
              </div>
            </div>
          ))}
        </div>
      );

      rows.push(row);
    }
    return pets.length > 0 ? rows : <Loading noText fast scroll={false} />;
  };

  const keepRevocationBtn = React.useMemo(() => {
    let policyStartDate = moment(selectedPetInfo?.quote?.policy?.dateInception, 'YYYY-MM-DD');
    let afterPeriodDate = selectedPetInfo?.policy?.customerType === "Opt-OUT" 
        ? policyStartDate.clone().add(90, 'days').format('YYYY-MM-DD') 
        : policyStartDate.clone().add(45, 'days').format('YYYY-MM-DD');
    
    return moment().isBetween(policyStartDate, afterPeriodDate);
}, [selectedPetInfo]);


  return (
    <ModalWrapper
      headerTitle={t(modalTitle(selectedType)[0])}
      openModal={openModalCancelContract}
      setModalWrapper={() => handleClose()}
    >
      {dialog === 'main' && (
        <>
          <div
            className="modal_container__body__text"
            style={{ maxWidth: 'unset' }}
          >
            {t('whichPetCancel')}
          </div>
          <div
            className="modal_container__body__inputs"
            style={{ rowGap: '5px' }}
          >
            {renderPets()}
          </div>
        </>
      )}

      {dialog === 'cancelationType' && (
        <>
          {checkingRevoke ? (
            <Loading noText fast scroll={false} />
          ) : (
            <>
              <div
                className="modal_container__body__text"
                style={{ maxWidth: 'unset' }}
              >
                {t('selectOption')}
              </div>
              <div className="modal_container__body__inputs">
                <div
                  className={`modal_container__body__button-modal wide ${selectedType === 'Ordinary cancelation' ? 'selected' : ''}`}
                  onClick={() => setSelectedType('Ordinary cancelation')}
                >
                  {t('Ordinary cancelation')}
                </div>
                <div
                  className={`modal_container__body__button-modal wide ${selectedType === 'Cancellation for other reasons' ? 'selected' : ''}`}
                  onClick={() =>
                    setSelectedType('Cancellation for other reasons')
                  }
                >
                  {t('Cancellation for other reasons')}
                </div>
                {/* Option below should be visible when the customer is within the revocation period. For "OP" and "Krankenschutz" tariffs, there is a 45-day revocation period, and for "Haftpflicht" there is a 14-day revocation period.*/}
                {canRevoke && keepRevocationBtn && (
                  <div
                    className={`modal_container__body__button-modal wide ${selectedType === 'Revocation' ? 'selected' : ''}`}
                    onClick={() => setSelectedType('Revocation')}
                  >
                    {t('Revocation')}
                  </div>
                )}
                <div className="modal_container__body__info">
                  <span>{t('hint')}</span>
                  {t('hintModal')}
                  <img src={Exclamation} />
                </div>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  onClick={() => {
                    setSelectedType(null);
                    setDialog('main');
                  }}
                >
                  {t('Back')}
                </div>
                <div
                  className={
                    selectedType !== null
                      ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                      : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                  }
                  onClick={
                    selectedType !== null
                      ? () => setDialog('typeSelected')
                      : (e) => e.stopPropagation()
                  }
                >
                  {t('Next')}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {dialog === 'typeSelected' &&
        selectedType === 'Ordinary cancelation' && (
          <>
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0 0 30px 0' }}
            >
              {t('ContractEnds1') +
                formatAnyDate(selectedPetInfo?.policy?.dateExpiry)}
            </div>
            <div className="modal_container__body__row-modal">
              <div
                className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                onClick={() => {
                  setDialog('cancelationType');
                }}
              >
                {t('Back')}
              </div>
              <div
                className={
                  selectedType !== null
                    ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                    : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                }
                onClick={
                  selectedType !== null
                    ? () => setDialog('areYouSureToCancel')
                    : (e) => e.stopPropagation()
                }
              >
                {t('Next')}
              </div>
            </div>
          </>
        )}

      {dialog === 'typeSelected' &&
        selectedType === 'Cancellation for other reasons' && (
          <>
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0' }}
            >
              {t('afterReason3')}
            </div>
            <div
              className="modal_container__body__inputs"
              style={{ margin: '40px 0' }}
            >
              <CustomSelect
                variant="modal-cancel-contract"
                options={circumstancesList}
                formatCreateLabel={(i) => i}
                placeholder={t('selectHere')}
                value={circumstances}
                onChange={(e) => setCircumstances(e)}
                isClearable={true}
              />
            </div>
            <div className="modal_container__body__row-modal">
              <div
                className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                onClick={() => {
                  setCircumstances(null);
                  setDialog('cancelationType');
                }}
              >
                {t('Back')}
              </div>
              <div
                className={
                  circumstances !== null
                    ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                    : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                }
                onClick={
                  circumstances !== null
                    ? () => setDialog('selectUpload')
                    : (e) => e.stopPropagation()
                }
              >
                {t('Next')}
              </div>
            </div>
          </>
        )}

      {dialog === 'selectUpload' && (
        <>
          <div
            className="modal_container__body__text"
            style={{ maxWidth: '540px' }}
          >
            {t('pleaseUpload')}
          </div>
          <div
            className="modal_container__body__uploader"
            style={{ maxWidth: '540px' }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              // accept=".jpg,.png"
              onChange={handleFileChange}
              hidden
              multiple
            />
            <img src={Upload} />
            <span>{t('docUploadPlaceholder')}</span>
            <div
              className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
              style={{
                width: 'unset',
                padding: isMobile ? '0 20px' : '0 39px',
              }}
            >
              {t('uploadDocument')}
            </div>
          </div>
          {selectedFiles.length ? (
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0 0 15px 0' }}
            >
              {t('uploadedDocuments')}
            </div>
          ) : null}
          {selectedFiles.length
            ? selectedFiles.map((file, ind) => (
              <div
                key={ind}
                className="modal_container__body__attach"
                style={{ maxWidth: '440px', margin: '0 0 20px 0' }}
              >
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t('addDocument')}
                  value={file.name || ''}
                />
                <img
                  src={Remove}
                  alt="Remove"
                  style={{
                    position: 'absolute',
                    right: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setSelectedFiles((prevSelectedFiles) =>
                      prevSelectedFiles.filter((el) => el.name !== file.name)
                    )
                  }
                />
              </div>
            ))
            : null}
          <div
            className="modal_container__body__row-modal"
            style={{ margin: '10px 0 0 0' }}
          >
            <div
              className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
              onClick={() => {
                setSelectedFiles([]);
                setDialog('typeSelected');
                setSelectedType('Cancellation for other reasons');
              }}
            >
              {t('Back')}
            </div>
            <div
              className={
                selectedFiles.length
                  ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                  : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
              }
              onClick={
                selectedFiles.length
                  ? () => setDialog('areYouSureToCancel')
                  : (e) => e.stopPropagation()
              }
            >
              {t('Next')}
            </div>
          </div>
        </>
      )}

      {((dialog === 'typeSelected' && selectedType === 'Revocation') ||
        dialog === 'areYouSureToCancel') && (
          <>
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0' }}
            >
              {t('areYouSureToCancel_start')}
            </div>
            <div className="modal_container__body__inputs">
              <div className="modal_container__body__button-modal selected no-pointer w-100">
                {selectedPetInfo?.data?.nameOfPet}
                <br />
                {t(
                  selectedPetInfo?.products?.petInsurance?.data?.selectedPackage
                )}
                <br />
                {selectedPetInfo?.policy?.policyNo}
              </div>
            </div>
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0 0 30px 0' }}
            >
              <Markup content={t(modalTitle(selectedType)[1]) + t('areYouSureToCancel_end')} />
            </div>
            <div className="modal_container__body__row-modal">
              <div
                className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                onClick={() => {
                  if (selectedType === 'Revocation') {
                    setDialog('cancelationType');
                  } else if (
                    dialog === 'areYouSureToCancel' &&
                    selectedType === 'Cancellation for other reasons'
                  ) {
                    setDialog('selectUpload');
                  } else {
                    setDialog('typeSelected');
                    setSelectedType((prevSelectedReason) => prevSelectedReason);
                  }
                }}
              >
                {t('Back')}
              </div>
              <div
                className={
                  selectedType !== null
                    ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                    : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
                }
                onClick={
                  selectedType !== null
                    ? () => {
                      (selectedType === 'Revocation' || selectedType === 'Ordinary cancelation')
                        ? cancelContract()
                        : handleSubmit()
                    }
                    : (e) => e.stopPropagation()
                }
              >
                {t('Next')}
              </div>
            </div>
          </>
        )}

      {dialog === 'emailSent' && (
        <>
          {submitting ? (
            <Loading noText fast scroll={false} />
          ) : (
            <>
              <div
                className="modal_container__body__text"
                style={{ maxWidth: '540px', margin: '0 0 30px 0' }}
              >
                {t(selectedType === 'Revocation' ? 'emailSent_revoke' : 'emailSent_main')}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                  style={{ width: 'unset' }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t('toStartPage')}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ModalWrapper>
  );
}

export default ModalCancelContract;
